export const patientInfo = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Patient First Name',
        name: 'patientfirstname',
        key: 'patientfirstname',
      },
      {
        type: 'text',
        label: 'Patient Last Name',
        name: 'patientlastname',
        key: 'patientlastname',
      },
      {
        type: 'text',
        label: 'Patient Date of Birth',
        name: 'dob',
        key: 'dob',
      },
    ],
  },
]
export const doctorInfo = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Doctor First Name',
        name: 'drfirstname',
        key: 'drfirstname',
      },
      {
        type: 'text',
        label: 'Doctor Last Name',
        name: 'drlastname',
        key: 'drlastname',
      },
      {
        type: 'text',
        label: 'Doctor Fax',
        name: 'drfax',
        key: 'drfax',
      },
      {
        type: 'text',
        label: 'Doctor Tel',
        name: 'drtel',
        key: 'drtel',
      },
      {
        type: 'text',
        label: 'CPSO',
        name: 'cpso',
        key: 'cpso',
      },
    ],
  },
]
export const pharmacyInfo = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Pharmacy Name',
        name: 'pharmacyName',
        key: 'pharmacyName',
      },
      {
        type: 'text',
        label: 'Pharmacy Tel Number',
        name: 'pharmacyTelephoneNumber',
        key: 'pharmacyTelephoneNumber',
      },
      {
        type: 'text',
        label: 'Pharmacy Fax Number',
        name: 'pharmacyFaxNumber',
        key: 'pharmacyFaxNumber',
      },
      {
        type: 'text',
        label: 'Pharmacy Address',
        name: 'pharmacyFullAdd',
        key: 'pharmacyFullAdd',
      },

    ],
  },
]
export const medChanges = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Dose Change (Increase or Decrease)',
        name: 'dosechange',
        key: 'dosechange',
      },
      {
        type: 'text',
        label: 'List Medications Discontinued:',
        name: 'discontinue',
        key: 'discontinue',
      },
      {
        type: 'text',
        label: 'List New Medications',
        name: 'newMedications',
        key: 'newMedications',
      },
    ],
  },
]
export const pharmacistinfo = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Pharmacist First Name',
        name: 'rphfirstname',
        key: 'rphfirstname',
      },
      {
        type: 'text',
        label: 'Pharmacist Last Name',
        name: 'rphlastname',
        key: 'rphlastname',
      },
      {
        type: 'text',
        label: 'OCP#',
        name: 'ocp',
        key: 'ocp',
      },

      // {
      //   type: 'text',
      //   label: 'Rx Number',
      //   name: 'rxNumber',
      //   key: 'rxNumber',
      // },
    ],
  },
]
export const medUpdates = [
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Dose Change (Increase or Decrease)',
        name: 'dosechange',
        key: 'dosechange',
      },
      {
        type: 'text',
        label: 'List Medications Discontinued:',
        name: 'discontinue',
        key: 'discontinue',
      },
      {
        type: 'text',
        label: 'List New Medications',
        name: 'newMedications',
        key: 'newMedications',
      },
    ],
  },
]
