<template>
  <div>
    <v-card
      id="patientformpanel"

      color="info"
      dark
    >
      <v-card-title class="justify-left">
        Blister Pack Medication Authorization Request
      </v-card-title>
      <v-row :key="componentTopCardKey">
        <v-col
          cols="12"
          md="4"
          sm="4"
        >
          <v-card-text class="text-center">
            <h2>{{ patientProfile['FIRST NAME'] }} {{ patientProfile['LAST NAME'] }}</h2>
            <p>Gender: {{ patientProfile['GENDER'] }} || DOB: {{ this.$moment(this.patientProfile['DATE OF BIRTH']).format('YYYY-MM-DD') }} </p>
          </v-card-text>
        </v-col>

        <v-col
          cols="12"
          md="4"
          sm="4"
        >
          <v-card-text class="text-center">
            <h2>Dr. {{ patientProfile['PRIMARY FIRST NAME'] }} {{ patientProfile['PRIMARY LAST NAME'] }}</h2>
            <p>Fax: {{ patientProfile['PRIMARY FAX #'] }} || Tel: {{ (this.patientProfile['PRIMARY PHONE #']) }} </p>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>

    <v-stepper
      v-model="activeStep"
      non-linear
      class="custom-header"
    >
      <v-stepper-header>
        <v-stepper-step
          :complete="activeStep > 1"
          step="1"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">01</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Doctor Selection</span>
              <span class="text--secondary text-xs">Please select Doctor for Request</span>
            </div>
          </div>
        </v-stepper-step>

        <v-divider></v-divider>

        <!-- Header: Step 2 -->
        <v-stepper-step
          step="2"
          :complete="activeStep > 2"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">02</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Data Review</span>
              <span class="text--secondary text-xs">Lets Gather the info</span>
            </div>
          </div>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3">
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">03</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Form Finalization</span>
              <span class="text--secondary text-xs">Lets Document the Request</span>
            </div>
          </div>
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card>
            <v-card-title>
              <p class="tw-text-blue-900 tw-text-2xl tw-font-bold">
                Doctor Selection
              </p>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              v-model="selectedDoctor"
              :search="search"
              show-select
              hide-default-footer
              :items="doctorList"
              item-key="DoctorSelectId"
              :headers="headers"
              class=""
              :single-select="singleSelect"
              fixed-header
            >
              <template slot="no-data">
                <tr>
                  <td
                    colspan="6"
                    class="pa-4"
                  >
                    No doctors to select from
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-expansion-panels v-model="dataPanel">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Medication Changes</span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 dark:tw-bg-darkmode">
                  <FormulateForm
                    :key="refreshFormulateForm"
                    v-model="prePopulate"
                    name="medChanges"
                    :schema="medChanges"
                    :keep-model-data="true"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-expansion-panels v-model="finalpanel">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Signatures</span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <pg-sig-input
                  @save="saveSignature"
                  @updatePharmacist="updatePharmacist"
                ></pg-sig-input>
                <!-- :doctor="prePopulate.drfirstname + ' ' + prePopulate.drlastname" -->
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Blister Pack Grid</span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-container fluid>
                  <v-data-table
                    :headers="blisterpackHeaders"
                    :items="blisterMedsList"
                    :search="search"
                    :items-per-page="15"
                    class="table-kitchen-sink"
                    item-key="RxNumber"
                  >
                  </v-data-table>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Patient Information: {{ prePopulate.patientfirstname }} {{ prePopulate.patientlastname }}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 dark:tw-bg-darkmode">
                  <FormulateForm
                    :key="refreshFormulateForm"
                    v-model="prePopulate"
                    name="patientInfo"
                    :schema="patientInfo"
                    :keep-model-data="true"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Doctor Information: Dr. {{ prePopulate['PRIMARY FIRST NAME'] }} {{ prePopulate['PRIMARY LAST NAME'] }} </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 dark:tw-bg-darkmode">
                  <FormulateForm
                    :key="refreshFormulateForm"
                    v-model="prePopulate"
                    name="doctorInfo"
                    :schema="doctorInfo"
                    :keep-model-data="true"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Pharmacist Information: {{ prePopulate.rphName }} </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 dark:tw-bg-darkmode">
                  <FormulateForm
                    :key="refreshFormulateForm"
                    v-model="prePopulate"
                    name="pharmacistinfo"
                    :schema="pharmacistinfo"
                    :keep-model-data="true"
                  />
                </div>
                <v-container>
                  <v-row
                    wrap
                    ml-4
                  >
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="12"
                      md="4"
                    >
                      <pharmacist-search></pharmacist-search>
                      <v-btn
                        id="v-step-5"
                        color="primary"
                        primary
                        class="my-3"
                        @click.native="openPharmacistOverlay"
                      >
                        Select a Pharmacist
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="6"
                      md="3"
                    >
                      <v-text-field
                        v-model="patientProfile['rphfirstname']"
                        label="Pharmacist Name"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="6"
                      md="3"
                    >
                      <v-text-field
                        v-model="patientProfile['ocp']"
                        label="OCP#"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Pharmacy Information {{ prePopulate.pharmacyName }}</span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 dark:tw-bg-darkmode">
                  <FormulateForm
                    :key="refreshFormulateForm"
                    v-model="prePopulate"
                    name="pharmacyInfo"
                    :schema="pharmacyInfo"
                    :keep-model-data="true"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-stepper-content>
      </v-stepper-items>
      <div

        class="d-flex justify-center align-center"
      >
        <v-card-actions>
          <pg-form-buttons
            :disabled-button="this.selectedDoctor.length ? false:true"
            :step="activeStep"
            :final-step="3"
            @next="next"
            @previous="previous"
          ></pg-form-buttons>

          <!-- <v-col>
            <v-btn
              v-show="activeStep === 1"
              color="success"
              large
              @click="nextstepone"
            >
              Next
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-show="activeStep === 2"
              color="warning"
              large
              @click="activeStep = 1"
            >
              Previous
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-show="activeStep === 3"
              color="warning"
              large
              @click="activeStep = 2"
            >
              Previous
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-show="activeStep === 2"
              color="success"
              large
              @click="nextstep"
            >
              Next
            </v-btn>
          </v-col> -->
          <v-col>
            <pg-submit
              v-show="activeStep === 3"
              id="v-step-7"
              form-type="blisterPackGrid"
              base-filename="blisterPackGrid"
              type-of-medscheck="typeOfMedscheck"
              :patient-data="prePopulate"
              @saveInfo="savePdfInfo($event)"
            >
              Submit
            </pg-submit>
            <save-patient-state
              :patient-profile="prePopulate"
              :label="saveState"
              @onload="OnLoad($event)"
              @click="statekeyRender"
            ></save-patient-state>
          </v-col>
        </v-card-actions>
      </div>
    </v-stepper>
    <v-dialog
      v-model="loadingPatient"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="pt-3">
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { mdiAlert, mdiCheckboxMarkedCircleOutline } from '@mdi/js'

import submitPdf from '@/components/common/ui/Form/Submit/ProcessPDF.vue'
import SavePatientState from '@/views/pages/tools/SaveDocumentState.vue'
import serverFormProcess from '@/views/Medscheck/Utils/processFormDataMedicationSelection'
import { stateService } from '@/render/api/zarya'
import {
  patientInfo,
  doctorInfo,
  pharmacyInfo,
  medChanges,
  pharmacistinfo,
} from '@/assets/formSchema/blisterPackGridSchema'
import pgSigInput from '@/components/common/ui/Form/pgSigInput/pgSigInput.vue'
import pgFormButtons from '@/views/patient/components/pg-form-buttons.vue'

export default {
  name: 'BlisterPackGrid',
  components: {
    'pg-submit': submitPdf,
    'save-patient-state': SavePatientState,
    'pg-sig-input': pgSigInput,
    'pg-form-buttons': pgFormButtons,
  },

  data() {
    this.$store.dispatch('setFormClass', 'blisterPackGrid')

    return {
      patientInfo,
      pharmacistinfo,
      pharmacyInfo,
      doctorInfo,
      medChanges,
      saveState: 'Save Progress',
      dateOfTransaction: '',
      dateTimeofTransaction: '',
      rxDetails: '',
      blisterMedsList: [],
      medicationList: [],
      finalpanel: 0,
      dataPanel: 0,
      refreshFormulateForm: 0,
      loadingPatient: false,
      componentKeyMedList: 0,
      singleSelect: true,
      componentTopCardKey: 0,
      selectedDoctor: [],
      prePopulate: {},
      search: '',
      doctorList: [],
      selectedMedication: [],
      icons: { mdiAlert, mdiCheckboxMarkedCircleOutline },
      refreshSignatureStatus: 0,
      activeStep: 1,
      initial: '',
      patientProfile: {},
      headers: [
        {
          text: 'Last Name',
          class: 'black--text font-weight-bold subtitle-1',
          align: 'left',
          value: 'DoctorLastName',
          sortable: false,
        },
        {
          text: 'First Name',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'DoctorFirstName',
          sortable: false,
        },
        {
          text: 'CPSO',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'CPSO',
          sortable: false,
        },
        {
          text: 'Address',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'DoctorAddress',
          sortable: false,
        },
        {
          text: 'Phone',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'DoctorPhone',
          sortable: false,
        },
        {
          text: 'Fax',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'DoctorFax',
          sortable: false,
        },
        {
          text: 'Prescriptions in Last Year',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'RXperProvider',
          sortable: false,
        },
      ],
      headersCard: [
        {
          text: 'Generic Name',
          align: 'left',
          sortable: false,
          value: 'GenericName',
        },
        {
          text: 'Strength',
          align: 'left',
          sortable: false,
          value: 'RxStrength',
        },
      ],
      blisterpackHeaders: [
        {
          text: 'Brand Name',
          align: 'start',
          value: 'BrandName',
        },
        {
          text: 'Strength',
          align: 'left',
          value: 'Strength',
        },
        {
          text: 'SIG',
          align: 'left',
          value: 'SIGFull',
        },
        {
          text: 'Brk',
          align: 'left',
          value: 'Brk',
        },
        {
          text: 'Noon',
          align: 'left',
          value: 'Noon',
        },
        {
          text: 'Supp',
          align: 'left',
          value: 'Supp',
        },
        {
          text: 'Bed',
          align: 'left',
          value: 'Bed',
        },
        {
          text: 'description',
          align: 'left',
          value: 'description',
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'FormClass',
      'PatientProfile', // get patient profile
      'PatientMedications', // get patient medication,
      'PharmacyProfile',
      'UserProfile',
    ]),
    ...mapState(['Patient']),
    conditionalSchema() {
      if (this.prePopulate.covidScreening !== 'Yes') {
        const children = this.fluConsentRadioSchema[0].children.filter(({ name }) => name !== 'seriousReaction')
        const schema = [
          {
            children,
            class: 'tw-grid tw-grid-cols-6 tw-gap-6',
            component: 'div',
          },
        ]

        return schema
      }
      const { children } = this.fluConsentRadioSchema[0]
      const schema = [
        {
          children,
          class: 'tw-grid tw-grid-cols-6 tw-gap-6',
          component: 'div',
        },
      ]

      return schema
    },
    conditionalConsent() {
      if (this.prePopulate.consentMyself !== 'agent') {
        const children = this.consentFluSchema[1].children.filter(
          ({ name }) =>
            name !== 'agentName' && name !== 'agentTel' && name !== 'agentRelation' && name !== 'agentRelationSpecify',
        )

        const schema = [
          {
            component: 'h3',
            children: 'Seasonal Influenza Vaccination Patient/Agent Consent',
            class: 'tw-text-2xl tw-mb-4',
          },
          {
            children,
            class: 'tw-grid tw-grid-cols-6 tw-gap-6',
            component: 'div',
          },
        ]

        return schema
      }
      if (this.prePopulate.agentRelation !== 'other') {
        const children = this.consentFluSchema[1].children.filter(({ name }) => name !== 'agentRelationSpecify')

        const schema = [
          {
            component: 'h3',
            children: 'Seasonal Influenza Vaccination Patient/Agent Consent',
            class: 'tw-text-2xl tw-mb-4',
          },
          {
            children,
            class: 'tw-grid tw-grid-cols-6 tw-gap-6',
            component: 'div',
          },
        ]

        return schema
      }
      const { children } = this.consentFluSchema[1]
      const schema = [
        {
          component: 'h3',
          children: 'Seasonal Influenza Vaccination Patient/Agent Consent',
          class: 'tw-text-2xl tw-mb-4',
        },
        {
          children,
          class: 'tw-grid tw-grid-cols-6 tw-gap-6',
          component: 'div',
        },
      ]

      return schema
    },
  },
  mounted() {
    this.populateWithPatientData()
    this.searchBlisterMeds()

    this.preloadForm()
    this.searchDoctor()
    this.refreshFormulateForm += 1
    this.saveDateSubmitted()
    this.$root.$on('Refresh_Table', patientID => {
      this.populateWithPatientData()
      this.searchBlisterMeds()
      this.saveDateSubmitted()
      this.searchDoctor()
      this.selectedPatient()
      this.preloadForm()
      this.refreshFormulateForm += 1
      this.step = '1'
      this.finalpanel = 0
      this.dataPanel = 0
    })
  },
  methods: {
    previous() {
      this.activeStep--
    },
    next() {
      this.activeStep++
      if (this.activeStep === 2) {
        this.populateWithPatientData()
        this.refreshSignatureStatus += 1
      }
      if (this.activeStep === 3) {
        this.searchBlisterMeds()
        this.refreshSignatureStatus += 1
      }
    },
    saveSignature({ pharmacistSig, patientSig }) {
      // FIXME: Fix the mapping on the form
      this.patientProfile.PharmacistSignature = pharmacistSig
      this.patientProfile.ptsignature = patientSig
    },
    updatePharmacist({ pharmacistName, ocp }) {
      this.patientProfile.rphfirstname = pharmacistName
      this.patientProfile.ocp = ocp
      this.prePopulate.rphName = pharmacistName
      this.prePopulate.rphfirstname = pharmacistName.split(' ')[0]
      this.prePopulate.rphlastname = pharmacistName.split(' ')[1]
      this.refreshFormulateForm += 1
    },
    selected(selectedMedication) {
      this.selectedMedication = selectedMedication
      this.patientProfile = {
        ...this.patientProfile,
        rphfirstname: `${this.$store.state.User.user.firstName} ${this.$store.state.User.user.lastName}`,
        ocp: this.$store.state.User.user.ocp,
        selectedMedication: this.selectedMedication,
        originalRxnumber: this.selectedMedication.length ? this.selectedMedication[0].RxNumber : '',
      }
    },
    async searchDoctor() {
      this.doctorList =
        (await this.$root.$system.DBAdapterConn.getSearchByDoctor(
          this.PatientProfile.PatientID,
          this.drFirstName,
          this.drLastName,
          this.cpso,
        )) || []
    },
    async populateWithPatientData() {
      this.prePopulate = {
        ...this.patientProfile,
        patientlastname: this.PatientProfile['LAST NAME'],
        patientfirstname: this.PatientProfile['FIRST NAME'],
        dob: this.$moment(this.PatientProfile['DATE OF BIRTH']).format('YYYY-MM-DD'),
        rphName: `${this.UserProfile.firstName} ${this.UserProfile.lastName}`,
        dateSigned: this.$moment(this.patientProfile.dateSubmitted).format('YYYY-MM-DD'),
        pharmacyID: this.$store.getters.PharmacyProfile._id,
        userID: this.$store.getters.UserProfile._id,
        patientID: this.$store.getters.PatientProfile.PatientID,
        PharmacistSignature: this.prePopulate.PharmacistSignature,
        drfirstname: this.selectedDoctor.length ? this.selectedDoctor[0].DoctorFirstName : '',
        drlastname: this.selectedDoctor.length ? this.selectedDoctor[0].DoctorLastName : '',
        drfax: this.selectedDoctor.length ? this.selectedDoctor[0].DoctorFax : '',
        drtel: this.selectedDoctor.length ? this.selectedDoctor[0].DoctorPhone : '',
        cpso: this.selectedDoctor.length ? this.selectedDoctor[0].CPSO : '',
        rphfirstname: this.UserProfile.firstName,
        rphlastname: this.UserProfile.lastName,
        ocp: this.UserProfile.ocp,
        pharmacyName: this.$store.state.User.pharmacy.name,
        pharmacyFaxNumber: this.$store.state.User.pharmacy.fax,
        pharmacyTelephoneNumber: this.$store.state.User.pharmacy.phone,
        pharmacyFullAdd: `${this.$store.state.User.pharmacy.address.streetNumber} ${this.$store.state.User.pharmacy.address.streetName}, ${this.$store.state.User.pharmacy.address.city} ${this.$store.state.User.pharmacy.address.state} ${this.$store.state.User.pharmacy.address.code}`,
      }

      // this.searchBlisterMeds()

      this.loadingPatient = false
    },
    preloadForm() {
      this.patientData = {
        ...this.$store.state.Patient.data,
        PharmacistSignature: '',
      }
      this.medicationsList = this.$store.state.Patient.medications
      this.patientProfile = {
        ...this.$store.state.Patient.data,
      }
    },
    selectedPatient() {
      this.prePopulate = {}
      this.activeStep = 1

      this.componentTopCardKey += 1

      // this.componentKeyinteractions += 1
      this.componentKeyMedList += 1
      this.populateWithPatientData()
    },
    savePdfInfo(data) {
      this.prePopulate.pdfRef = {
        ...data,
      }
      this.updateStateStatus()
    },
    selectedDoctorClick() {
      this.prePopulate = {
        ...this.prePopulate,
        drName: `${this.selectedDoctor[0].DoctorFirstName} ${this.selectedDoctor[0].DoctorLastName}`,
        drFax: this.selectedDoctor[0].DoctorFax,
        cpso: this.selectedDoctor[0].CPSO,
        drTel: this.selectedDoctor[0].DoctorPhone,
      }
    },
    saveDateSubmitted() {
      const current = new Date()
      const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`
      const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
      const dateTime = `${date} ${time}`
      this.patientProfile.dateSubmitted = dateTime
      this.dateOfTransaction = date
      this.dateTimeofTransaction = dateTime
    },
    async searchBlisterMeds() {
      this.blisterMedsList =
        (await this.$root.$system.DBAdapterConn.getBlisterPackMedications(this.patientProfile.PatientID)) || []
      this.blisterMedsList.forEach((medication, index) => {
        this.prePopulate[`med${index + 1}`] = this.blisterMedsList[index].GenericName
        this.prePopulate[`medb${index + 1}`] = this.blisterMedsList[index].BrandName
        this.prePopulate[`str${index + 1}`] = this.blisterMedsList[index].Strength
        this.prePopulate[`sig${index + 1}`] = this.blisterMedsList[index].SIGFull
        this.prePopulate[`Brk${index + 1}`] = this.blisterMedsList[index].Brk
        this.prePopulate[`Noon${index + 1}`] = this.blisterMedsList[index].Noon
        this.prePopulate[`Supp${index + 1}`] = this.blisterMedsList[index].Supp
        this.prePopulate[`Bed${index + 1}`] = this.blisterMedsList[index].Bed
      })
    },
    async updateStateStatus() {
      this.saveDateSubmitted()
      let res
      const data = {
        formType: this.$store.state.Patient.FORM_CLASS,
        state: this.prePopulate,
        patientID: this.patientProfile.PatientID,
        userID: this.UserProfile._id,
        pharmacyID: this.PharmacyProfile._id,
      }

      this.$router.push('/patient/patientdashboard')
      const token = await this.$auth.getTokenSilently()
      try {
        if (!this.patientProfile.stateId) {
          // create it and make its status complete (this is in case they complete a medscheck and it doesnt have a stateid already -- not working )
          // eslint-disable-next-line no-undef
          res = await stateService.createState(token, {
            ...data,
          })
          this.patientProfile.stateId = res.state._id
        }

        // eslint-disable-next-line no-undef
        res = await stateService.updateState(token, this.patientProfile.stateId, {
          ...data,
          status: 'complete',
        })

        return res
      } catch (err) {
        console.log(err)
      }

      // update it - once medcheck is complete and it has state id, change to complete -- works
    },
    nextstepone() {
      this.populateWithPatientData()

      // this.medicationsForPDF(this.patientData)
      this.activeStep = 2
      this.refreshSignatureStatus += 1
    },
    nextstep() {
      this.activeStep = 3
      this.refreshSignatureStatus += 1
      this.searchBlisterMeds()
    },
    ProcessMeds(patientData) {
      return serverFormProcess(this.selectedMedication, this.medicationsForPDF(patientData))
    },
    statekeyRender() {
      this.componentKeyinteractions += 1
    },
  },
}
</script>

<style lang="scss" scoped>
#patientformpanel {
  div.v-card__title.justify-left {
    color: rgb(248, 248, 248) !important;
  }
  div.row {
    color: rgb(248, 248, 248) !important;
  }
  div.row div:nth-child(1) div h2 {
    color: rgb(248, 248, 248) !important;
  }
  div.row div:nth-child(2) div h2 {
    color: rgb(248, 248, 248) !important;
  }
  div.row div:nth-child(3) div h2 {
    color: rgb(248, 248, 248) !important;
  }
}

#headersTable {
  .theme--dark.v-data-table {
    background-color: #95a6df80 !important;
    color: #ffffff !important;
  }
  .theme--dark.v-data-table td {
    color: rgb(255 255 255) !important;
  }
}
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #000000, #000000);
  background-origin: border-box;
  background-clip: content-box, border-box;
  justify-content: center !important;
  align-content: center !important;
}
.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}
.Signaturecontainer {
  width: '100%';
  padding: 8px 16px;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 20;
}
#noPadding {
  padding: 0;
}
.fixedElement {
  /* position: fixed; */
  padding: 1% 2%;
  width: 100%;
  z-index: 20;
}
#buttons {
  margin: 1%;
}
.centerButtons {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}
.centerButtons button {
  margin: 0 2%;
}
iframe {
  width: 1px;
  min-width: 100%;
}
</style>
